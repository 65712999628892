import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatSort } from '@angular/material/sort'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { NotificationService } from 'src/app/services/notification.service'
import { IStudent } from '../../../../models/Student.model'
import { StudentAssignmentsDialogComponent } from '../../../../shared/popups/Assignment-Dialogs/student-assignments/student-assignments.component'
import { ConfirmationPopupComponent } from '../../../../shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { EditStudentDialogComponent } from '../../../../shared/popups/Student-Dialogs/edit-student/edit-student.component'
import { EStudentActions, RemoveStudent, RemoveStudentSuccess } from '../../../../store/actions/students.actions'
import { IAppState } from '../../../../store/state/app.state'

@Component({
  selector: 'app-list-students',
  templateUrl: './list-students.component.html',
  styleUrls: ['./list-students.component.scss'],
})
export class ListStudentsComponent implements OnInit, OnDestroy {
  @Input() schoolId: number
  @Input() classroomId: number
  @Input() students: IStudent[]

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _store: Store<IAppState>,
    private _notificationService: NotificationService,
    public translate: TranslateService,
    private updates$: Actions,
  ) { }

  private unsubscribe$ = new Subject()

  @ViewChild(MatSort) sort: MatSort

  public displayedColumns: string[] = ['index', 'subuser.name', 'studentCode', 'incompleteAssignments', 'actions']

  public dataSource: MatTableDataSource<IStudent>

  public data = false

  ngOnInit() {
    this.data = true
    this.dataSource = new MatTableDataSource<IStudent>(this.students as IStudent[])
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)

      return item[property]
    }

    this.dataSource.sort = this.sort

    this.updates$.pipe(ofType<RemoveStudentSuccess>(EStudentActions.RemoveStudentSuccess), takeUntil(this.unsubscribe$)).subscribe((res: RemoveStudentSuccess) => {
      this._notificationService.showNotification(this.translate.instant('STUDENT.DELETED'), 2)
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  editStudent(student) {
    const dialogRef = this._dialog.open(EditStudentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: student,
    })
  }

  removeStudent(student) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        question: 'STUDENT.REMOVE_CONFIRM',
        extraConfirmation: true,
      },
    })

    dialog.afterClosed().subscribe((answer) => {
      if (answer === true) {
        this._store.dispatch(new RemoveStudent(student))
      }
    })
  }

  openAssignmentsDialog(student) {
    this._dialog.open(StudentAssignmentsDialogComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        inDashboard: true,
        schoolId: this.schoolId,
        classroomId: this.classroomId,
        student,
      },
    })
  }
}
