  <div class="video">
    <video
      (contextmenu)="noDownload($event)"
      #video
      id="video"
      class="player"
      controlsList="nodownload"
      controls
      color="accent"
      [autoplay]="true"
      (loadeddata)="videoLoaded(video)"
      (play)="videoStarted(video)"
      [src]="media.questions[0].src"
      (ended)="exitFullScreen()"
    >
    </video>
    <ng-template [featureEnabled]="'video-subtitles'">
      <div id="main-subtitle" class="subtitle overlay main"></div>
      <div id="second-subtitle" class="subtitle overlay second"></div>
      <form [formGroup]="subtitlesForm" *ngIf="media.subtitles.length > 0">
        <mat-form-field>
          <label for="subtitle1">Subtitles</label>
          <mat-select formControlName="subtitle1">
            <mat-option value="0"><span>None</span></mat-option>
            <mat-option [value]="sub.language" *ngFor="let sub of media.subtitles"><span>{{sub.language}}</span></mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="media.subtitles.length > 1">
          <label for="subtitle2">Secondary Subtitles</label>
          <mat-select formControlName="subtitle2">
            <mat-option value="0"><span>None</span></mat-option>
            <mat-option [value]="sub.language" *ngFor="let sub of media.subtitles"><span>{{sub.language}}</span></mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </ng-template>
  </div>