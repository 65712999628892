import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule, TransferState } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { NgxPaginationModule } from 'ngx-pagination';
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login'
import { provideUnleashProxy } from './helpers/unleash.initializer';

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthGuard } from './helpers/guards/auth.guard'
import { YourComponentCanDeactivateGuardService } from './helpers/guards/deactivate-page.guard'
import { NoneAuthGuard } from './helpers/guards/no-auth.guard'
import { AuthEffects } from './store/effects/auth.effects'
import { SubuserEffects } from './store/effects/subuser.effects'
import { UserEffects } from './store/effects/user.effects'
import { appReducers } from './store/reducers/app.reducers'
import { AuthModule } from './views/auth/auth.module'
import { ProfileModule } from './views/profile/profile.module'

import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { CheckRoleGuard } from './helpers/guards/check-role.guard'
import { RefreshTokenInterceptor } from './helpers/interceptors/refresh.interceptor'
import { langURLLocalizationsResolver } from './helpers/resolvers/langURLLocalizations.resolver'
import { defaultLanguageResolver } from './helpers/resolvers/defaultLanguage.resolver'
import { SubuserResolver } from './helpers/resolvers/subuser.resolver'
import { UserResolver } from './helpers/resolvers/user.resolver'
import { LoadingScreenModule } from './shared/components/loading-overlay/loading.module'
import { MemoryCardsGameModule } from './shared/games/memory-cards/memory-cards.module'
import { AssignmentEffects } from './store/effects/assignment.effects'
import { BooksEffects } from './store/effects/books.effects'
import { ClassroomsEffects } from './store/effects/classrooms.effects'
import { CoursesEffects } from './store/effects/courses.effects'
import { LessonsEffects } from './store/effects/lessons.effects'
import { NotificationEffects } from './store/effects/notification.effects'
import { PaymentEffects } from './store/effects/payment.effects'
import { ProgressReportsEffects } from './store/effects/progress-reports.effects'
import { ReportsEffects } from './store/effects/reports.effects'
import { SchoolEffects } from './store/effects/school.effects'
import { StatisticsEffects } from './store/effects/statistics.effects'
import { StudentsEffects } from './store/effects/students.effects'
import { TeachersEffects } from './store/effects/teachers.effects'
import { ThumbnailsEffects } from './store/effects/thumbnails.effects'
import { LoginAdminModule } from './views/auth/login-admin/login-admin.module'
import { LandingModule } from './views/landing/landing.module'

import { TransferHttpCacheModule } from '@nguniversal/common'
import { PasswordValidator } from 'src/app/helpers/validators/password.validator'
import { translateBrowserLoaderFactory } from './helpers/loaders/translate-browser.loader'

import { LottieCacheModule, LottieModule } from 'ngx-lottie'
import { LandingRedirectGuard } from './helpers/guards/landingRedirect.guard'
import { LandingRedirectService } from './services/landingRedirect.service'
import { NotificationsModule } from 'src/app/shared/components/notifications/notifications.module'
import { environment } from '../environments/environment'

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient)
}

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ProfileModule,
    MemoryCardsGameModule,
    SocialLoginModule,
    AuthModule,
    LandingModule,
    LoginAdminModule,
    MatSnackBarModule,
    LoadingScreenModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      UserEffects,
      PaymentEffects,
      LessonsEffects,
      CoursesEffects,
      StatisticsEffects,
      AuthEffects,
      SubuserEffects,
      ThumbnailsEffects,
      NotificationEffects,
      BooksEffects,
      ReportsEffects,
      StudentsEffects,
      ClassroomsEffects,
      ProgressReportsEffects,
      TeachersEffects,
      SchoolEffects,
      AssignmentEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    NgxPaginationModule,
    NotificationsModule,
  ],
  providers: [
    provideUnleashProxy({
      url: 'https://unleash.dinolingo.com/api/frontend',
      clientKey: environment.unleashKey,
      appName: 'dinolingo-web',
      environment: environment.name
    }),
    LandingRedirectGuard,
    AuthGuard,
    NoneAuthGuard,
    CheckRoleGuard,
    SubuserResolver,
    UserResolver,
    LandingRedirectService,
    LocalizeHelperService,
    YourComponentCanDeactivateGuardService,
    defaultLanguageResolver,
    langURLLocalizationsResolver,
    PasswordValidator,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('989682802655-5ilj17j0317chs1pfvsj11j5nhbd477i.apps.googleusercontent.com'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2467632186769654')
          }
        ],
        onError: (err) => {
          console.error(err)
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
