<div class="lessonCompleted">
  <button appSounds (click)="back()" mat-icon-button color="warn">
    <img src="/assets/images/icons/closeIcon.svg" alt="close" />
  </button>
  <img class="dino" src="/assets/images/pictures/dinoCourse.svg" alt="dino image" />
  <div class="cong">
    <div *ngIf="lesson.type.name !== 'Mini Test' && lesson.type.name !== 'Unit Test'">
      <span class="title">{{ 'LESSONS.LESSON_END.TITLE' | translate }}</span>
      <span>{{ 'LESSONS.LESSON_END.TITLE' | translate }}</span>
    </div>
    <div
      class="test-result completed"
      [ngClass]="{
        bronze: progress >= 80 && progress < 90,
        silver: progress >= 90 && progress < 100,
        gold: progress >= 100
      }"
      *ngIf="lesson.type.name == 'Mini Test' || lesson.type.name == 'Unit Test'"
    >
      <span *ngIf="progress >= 80" class="title">{{ 'LESSONS.LESSON_END.CONGRATULATIONS' | translate }}</span>
      <span *ngIf="progress >= 80">{{ 'LESSONS.LESSON_END.COMPLETE' | translate }}</span>
      <span *ngIf="progress < 80">{{ 'LESSONS.LESSON_END.NOT_COMPLETE' | translate }}</span>
      <span *ngIf="progress >= 80">{{ progress }}% {{ 'LESSONS.LESSON_END.CORRECT' | translate }}</span>
      <span *ngIf="progress < 80">{{ progress }}% {{ 'LESSONS.LESSON_END.UNCORRECT' | translate }}</span>
    </div>
  </div>
  <div class="new-dino" *ngIf="!!newDino">
    <span>{{ 'LESSONS.LESSON_END.NEW_DINO' | translate }}</span>
    <div>
      <ng-lottie class="lottie_p" style="width: 100px; height: 100px" [options]="{ path: newDino.picture || lesson.dinosaur.picture }"> </ng-lottie>

      <span>{{ newDino.name || lesson.dinosaur.name }}</span>
    </div>
  </div>
  <div class="coins" *ngIf="completed && subuserStars && !newDino">
    <img *ngIf="lesson.type.name == 'Mini Test' || lesson.type.name == 'Unit Test'" class="coin" src="/assets/images/coin-100.png" alt="coin" />
    <img *ngIf="subuserStars > 0 && (lesson.type.name == 'Video' || lesson.type.name == 'Video Song')" class="coin" src="/assets/images/coin-50.png" alt="coin" />
    <img *ngIf="lesson.type.name == 'Book'" class="coin" src="/assets/images/coin-25.png" alt="coin" />
    <img *ngIf="lesson.type.name == 'Game'" class="coin" src="/assets/images/coin-75.png" alt="coin" />
  </div>
  <div class="navigation">
    <button appSounds class="repeat" (click)="repeat()" *ngIf="!isTeacherArea">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'BUTTONS.REPEAT' | translate }}</span>
      </div>
    </button>
    <div *ngIf="lesson.type.name == 'Mini Test' || lesson.type.name == 'Unit Test'">
      <button *ngIf="isHasReports" mat-button color="warn" (click)="showResult()">
        {{ 'LESSONS.LESSON_END.RESULT' | translate }}
      </button>
    </div>
    <button appSounds class="next" (click)="back()">
      <div class="circle"></div>
      <div class="text-cont">
        <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
      </div>
    </button>
  </div>
</div>
