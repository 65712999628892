import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { ILessonsState } from '../state/lessons.state'

const lessonsState = (state: IAppState) => state.lessons

export const selectDisplayHeader = createSelector(lessonsState, (state: ILessonsState) => state.displayHeader)

export const selectCourseLessons = createSelector(lessonsState, (state: ILessonsState) => state.lessons)

export const selectUnitsWithLessons = createSelector(lessonsState, (state: ILessonsState) => state.unitsWithLessons)

export const selectCoursePrints = createSelector(lessonsState, (state: ILessonsState) => state.prints)

export const selectCoursePrintsCount = createSelector(lessonsState, (state: ILessonsState) => state.printsCount)

export const selectCurrentLesson = createSelector(lessonsState, (state: ILessonsState) => state.currentLesson)

export const selectCurrentLessonVideos = createSelector(lessonsState, (state: ILessonsState) => state.currentLessonVideos)

export const selectCurrentLessonGames = createSelector(lessonsState, (state: ILessonsState) => state.currentLessonGames)

export const selectCurrentLessonTest = createSelector(lessonsState, (state: ILessonsState) => state.currentLessonTest)

export const selectCurrentPreference = createSelector(lessonsState, (state: ILessonsState) => state.currentPreference)

export const selectCompleteLessonResponse = createSelector(lessonsState, (state: ILessonsState) => state.completeLessonResponse)
