<section class="loginWrapper">
  <div class="header">
    <div class="titleWrapper">
      <a routerLink="{{ getURLLangPart() }}/">
        <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
      </a>
    </div>
    <localization></localization>
  </div>
  <div class="content">
    <div class="details">
      <form [formGroup]="loginFormGroup" class="loginForm">
        <h1 class="baloo">{{ 'PROFILE.LOGIN_PT' | translate }}</h1>

        <mat-progress-bar mode="query" [value]="100" class="mat-progress-bar-buffer" *ngIf="loading"></mat-progress-bar>

        <div class="inputGroup">
          <input matInput type="email" required placeholder="{{ 'PROFILE.EMAIL' | translate }}"
            formControlName="email" />
          <ng-container *ngIf="(email.touched || email.dirty) && email.errors">
            <mat-error *ngIf="email.errors.required">
              {{ 'PROFILE.EMAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="email.errors.email">
              {{ 'PROFILE.EMAIL_ERROR' | translate }}
            </mat-error>
          </ng-container>
        </div>

        <div class="inputGroup">
          <input matInput type="password" [type]="hidePassword ? 'password' : 'text'" required
            placeholder="{{ 'PROFILE.PASSWORD' | translate }}" formControlName="password" />
          <div class="hide">
            <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility'
              }}</mat-icon>
          </div>
          <ng-container *ngIf="(password.touched || password.dirty) && password.errors">
            <mat-error *ngIf="password.errors.required">
              {{ 'PROFILE.PASSWORD_REQUIRED' | translate }}
            </mat-error>
          </ng-container>
        </div>

        <p class="forgot-password">
          <a routerLink="{{ getURLLangPart() }}/auth/restore-password">{{ 'PROFILE.PASSWORD_ERROR' | translate }}</a>
        </p>


        <div class="inputGroup nolabel">
          <button appSounds mat-raised-button class="login-btn" type="submit" (click)="login(loginFormGroup)"
            [disabled]="loginFormGroup.invalid" color="accent">
            {{ 'BUTTONS.LOGIN' | translate }}
          </button>
        </div>

        <div class="inputGroup nolabel">
          <button appSounds mat-raised-button class="login-btn uppercase" type="submit" (click)="studentLogin()"
            color="accent">
            {{ 'BUTTONS.LOGIN_STUDENT' | translate }}
          </button>
        </div>

        <div class="inputGroup nolabel">
          <a appSounds mat-raised-button class="signup-btn" routerLink="{{ getURLLangPart() }}/auth/registration">
            {{ 'PROFILE.ACCOUNT_CREATE_FREE' | translate }}
          </a>
        </div>

        <ng-template [featureEnabled]="'web-social-login'" (featureStateChange)="onSocialLoginFeature($event)">
          <div class="inputGroup small">
            <asl-google-signin-button type='standard' size='medium'></asl-google-signin-button>
          </div>

          <div class="inputGroup small">
            <div id="appleid-signin" data-border-radius="15" data-width="200" data-height="32" data-type="sign in">
            </div>
          </div>
        </ng-template>

        <div class="app-store">
          <p>
            <a href="https://apps.apple.com/us/app/dinolingo-languages-for-kids/id1537076111" target="_blank">
              <img src="/assets/images/store/ios-white.png" />
            </a>
          </p>
          <p>
            <a href="https://play.google.com/store/apps/details?id=com.dinolingo.dinolearning" target="_blank">
              <img src="/assets/images/store/gp-white.png" />
            </a>
          </p>
        </div>

        <p>{{ 'PROFILE.CANT_LOGIN' | translate }}</p>
        <p>
          <a target="_blank" href="https://wp.dinolingo.com/login/">{{ 'PROFILE.LOGIN_TO_OLD_SITE' | translate }}</a>
        </p>

      </form>
    </div>
    <div class="dino-help">
      <img src="assets/images/pictures/payment/dino-full.svg" />
      <a target="_blank" href="https://help.dinolingo.com/">
        {{ 'PROFILE.HELP' | translate }}
      </a>
    </div>
  </div>
</section>