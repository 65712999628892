export const getLanguageMap = (): any[] => {
  return [
    { abbr: 'ar', name: 'Arabic', course: 'arabic' },
    { abbr: 'hy', name: 'Armenian', course: 'armenian' },
    { abbr: 'yue', name: 'Cantonese', course: 'cantonese' },
    { abbr: 'zh', name: 'Chinese', course: 'chinese' },
    { abbr: 'bi', name: 'Creole', course: 'creole' },
    { abbr: 'hr', name: 'Croatian', course: 'croatian' },
    { abbr: 'nl', name: 'Dutch', course: 'dutch' },
    { abbr: 'en', name: 'English', course: 'english' },
    { abbr: 'pt', name: 'European Portuguese', course: 'portuguese-eu' },
    { abbr: 'fr', name: 'French', course: 'french' },
    { abbr: 'de', name: 'German', course: 'german' },
    { abbr: 'el', name: 'Greek', course: 'greek' },
    { abbr: 'he', name: 'Hebrew', course: 'hebrew' },
    { abbr: 'hi', name: 'Hindi', course: 'hindi' },
    { abbr: 'hu', name: 'Hungarian', course: 'hungarian' },
    { abbr: 'id', name: 'Indonesian', course: 'indonesian' },
    { abbr: 'it', name: 'Italian', course: 'italian' },
    { abbr: 'jp', name: 'Japanese', course: 'japanese' },
    { abbr: 'ko', name: 'Korean', course: 'korean' },
    { abbr: 'fa', name: 'Farsi - Persian', course: '' },
    { abbr: 'pl', name: 'Polish', course: 'polish' },
    { abbr: 'pt-br', name: 'Portuguese', course: 'portuguese-br' },
    { abbr: 'ro', name: 'Romanian', course: 'romanian' },
    { abbr: 'rom', name: 'Roman', course: 'roman' },
    { abbr: 'ru', name: 'Russian', course: 'russian' },
    { abbr: 'sr', name: 'Serbian', course: 'serbian' },
    { abbr: 'es', name: 'Spanish', course: 'spanish' },
    { abbr: 'es_US', name: 'Spanish (US)', course: 'spanish-us' },
    { abbr: 'tl', name: 'Tagalog', course: 'tagalog' },
    { abbr: 'th', name: 'Thai', course: 'thai' },
    { abbr: 'tr', name: 'Turkish', course: 'turkish' },
    { abbr: 'ua', name: 'Ukrainian', course: 'ukrainian' },
    { abbr: 'vi', name: 'Vietnamese', course: 'vietnamese' },
  ]
}
