<div class="editProfile">
  <div class="actions">
    <span class="title">{{ 'PROFILE.EDIT' | translate }}</span>
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
  </div>

  <div class="info">
    <div class="inputs">
      <form class="editProfileForm" [formGroup]="editProfileForm">
        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="firstName">{{ 'PLACEHOLDERS.FIRST_NAME' | translate }}</label>
            <input pattern="[a-zA-Z]*" maxlength="10" formControlName="firstName" matInput placeholder="{{ 'PLACEHOLDERS.FIRST_NAME' | translate }}" value="" />
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="lastName">{{ 'PLACEHOLDERS.LAST_NAME' | translate }}</label>
            <input pattern="[a-zA-Z]*" maxlength="10" formControlName="lastName" matInput placeholder="{{ 'PLACEHOLDERS.LAST_NAME' | translate }}" value="" />
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="email">{{ 'PROFILE.EMAIL' | translate }}</label>
            <input formControlName="email" matInput type="email" [readonly]="user.appleId || user.googleId" />
          </div>
        </div>

        <div *ngIf="user.role === 'parent' || user.pin" class="form-fields-container full-width">
          <div class="form-field-container">
            <label for="pin">{{ 'PROFILE.PIN' | translate }}</label>
            <input formControlName="pin" pattern="[0-9]*" maxlength="4" matInput placeholder="{{ 'PROFILE.PIN' | translate }}" />
          </div>
        </div>

        <div class="form-fields-container full-width">
          <div class="form-field-container">
            <label>{{ 'PROFILE.TIME_ZONE' | translate }}</label>
            <mat-select formControlName="timeZone">
              <mat-option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</mat-option>
              <mat-option value="-11:00">(GMT -11:00) Midway Island, Samoa</mat-option>
              <mat-option value="-10:00">(GMT -10:00) Hawaii</mat-option>
              <mat-option value="-09:50">(GMT -9:30) Taiohae</mat-option>
              <mat-option value="-09:00">(GMT -9:00) Alaska</mat-option>
              <mat-option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</mat-option>
              <mat-option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</mat-option>
              <mat-option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</mat-option>
              <mat-option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</mat-option>
              <mat-option value="-04:50">(GMT -4:30) Caracas</mat-option>
              <mat-option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</mat-option>
              <mat-option value="-03:50">(GMT -3:30) Newfoundland</mat-option>
              <mat-option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</mat-option>
              <mat-option value="-02:00">(GMT -2:00) Mid-Atlantic</mat-option>
              <mat-option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</mat-option>
              <mat-option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</mat-option>
              <mat-option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</mat-option>
              <mat-option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</mat-option>
              <mat-option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</mat-option>
              <mat-option value="+03:50">(GMT +3:30) Tehran</mat-option>
              <mat-option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</mat-option>
              <mat-option value="+04:50">(GMT +4:30) Kabul</mat-option>
              <mat-option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</mat-option>
              <mat-option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</mat-option>
              <mat-option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</mat-option>
              <mat-option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</mat-option>
              <mat-option value="+06:50">(GMT +6:30) Yangon, Mandalay</mat-option>
              <mat-option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</mat-option>
              <mat-option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</mat-option>
              <mat-option value="+08:75">(GMT +8:45) Eucla</mat-option>
              <mat-option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</mat-option>
              <mat-option value="+09:50">(GMT +9:30) Adelaide, Darwin</mat-option>
              <mat-option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</mat-option>
              <mat-option value="+10:50">(GMT +10:30) Lord Howe Island</mat-option>
              <mat-option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</mat-option>
              <mat-option value="+11:50">(GMT +11:30) Norfolk Island</mat-option>
              <mat-option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</mat-option>
              <mat-option value="+12:75">(GMT +12:45) Chatham Islands</mat-option>
              <mat-option value="+13:00">(GMT +13:00) Apia, Nukualofa</mat-option>
              <mat-option value="+14:00">(GMT +14:00) Line Islands, Tokelau</mat-option>
            </mat-select>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="buttons">
    <button mat-raised-button color="accent" [disabled]="!editProfileForm.valid" (click)="updateUser(editProfileForm.value)">
      {{ 'BUTTONS.SAVE' | translate }}
    </button>
  </div>
</div>
