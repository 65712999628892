import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators'
import { IAssignment } from '../../models/Assignment.model'
import { NotificationService } from '../../services/notification.service'
import { SchoolService } from '../../services/school.service'
import { StudentService } from '../../services/student.service'
import {
  AddAssignment,
  AddAssignmentFailure,
  AddAssignmentSuccess,
  AdjustStudentGrade,
  AdjustStudentGradeFailure,
  AdjustStudentGradeSuccess,
  CompleteAssignment,
  CompleteAssignmentFailure,
  CompleteAssignmentSuccess,
  DeleteAssignment,
  DeleteAssignmentFailure,
  DeleteAssignmentSuccess,
  EAssignmentActions,
  EditAssignment,
  EditAssignmentFailure,
  EditAssignmentSuccess,
  GetAssignments,
  GetAssignmentsFailure,
  GetAssignmentsSuccess,
  GetStudentAssignments,
  GetStudentAssignmentsFailure,
  GetStudentAssignmentsSuccess,
  GetStudentGrades,
  GetStudentGradesFailure,
  GetStudentGradesSuccess,
  GetStudentsAssignments,
  GetStudentsAssignmentsFailure,
  GetStudentsAssignmentsSuccess,
  GetStudentsGrades,
  GetStudentsGradesFailure,
  GetStudentsGradesSuccess,
} from '../actions/assignment.actions'
import { AddAssignmentToLesson, RemoveAssignmentFromLesson } from '../actions/lessons.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class AssignmentEffects {
  constructor(private _actions$: Actions, private _schoolService: SchoolService, private _studentService: StudentService, private _store: Store<IAppState>, private _notificationService: NotificationService) {}

  addAssignment: Observable<AddAssignmentSuccess | AddAssignmentFailure | AddAssignmentToLesson> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddAssignment>(EAssignmentActions.AddAssignment),
      exhaustMap((action: AddAssignment) => {
        return this._schoolService.addAssignment(action.schoolId, action.classroomId, action.payload).pipe(
          mergeMap((assignment: IAssignment) => [
            new AddAssignmentSuccess(assignment),
            new AddAssignmentToLesson(assignment)
          ]),
          catchError((error) => {
            this._notificationService.showNotificationError(error.error.clientMessage, 2);
            return of(new AddAssignmentFailure(error));
          })
        );
      })
    )
  );
  
  editAssignment: Observable<EditAssignmentSuccess | EditAssignmentFailure | AddAssignmentToLesson> = createEffect(() =>
    this._actions$.pipe(
      ofType<EditAssignment>(EAssignmentActions.EditAssignment),
      switchMap((action: EditAssignment) => {
        return this._schoolService.editAssignment(action.schoolId, action.classroomId, action.assignmentId, action.payload)
      }),
      exhaustMap((assignment: IAssignment) => of(new EditAssignmentSuccess(assignment), new AddAssignmentToLesson(assignment))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new EditAssignmentFailure(error))
      })
    )
  )

  getStudentAssignments: Observable<GetStudentAssignmentsSuccess | GetStudentAssignmentsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentAssignments>(EAssignmentActions.GetStudentAssignments),
      mergeMap((action) => {
        return this._studentService.getStudentAssignments(action.schoolId, action.classroomId, action.studentId, action.all).pipe(
          map((assignments: Array<IAssignment>) => new GetStudentAssignmentsSuccess(assignments)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentAssignmentsFailure(error))
          })
        )
      })
    )
  )

  getStudentsAssignments: Observable<GetStudentsAssignmentsSuccess | GetStudentsAssignmentsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentsAssignments>(EAssignmentActions.GetStudentsAssignments),
      mergeMap((action) => {
        return this._studentService.getStudentsAssignments(action.schoolId, action.classroomId, action.offset, action.limit).pipe(
          map((assignments: any) => new GetStudentsAssignmentsSuccess(assignments, action.append)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetStudentsAssignmentsFailure(error))
          })
        )
      })
    )
  )

  getAssignments: Observable<GetAssignmentsSuccess | GetAssignmentsFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAssignments>(EAssignmentActions.GetAssignments),
      mergeMap((action) => {
        return this._schoolService.getAssignments(action.schoolId, action.classroomId).pipe(
          map((assignments: Array<IAssignment>) => new GetAssignmentsSuccess(assignments)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetAssignmentsFailure(error))
          })
        )
      })
    )
  )

  completeAssignment$: Observable<CompleteAssignmentSuccess | CompleteAssignmentFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<CompleteAssignment>(EAssignmentActions.CompleteAssignment),
      switchMap((action: CompleteAssignment) => {
        return this._schoolService.completeAssignment(action.schoolId, action.classroomId, action.payload)
      }),
      exhaustMap((response: any) => of(new CompleteAssignmentSuccess(response))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new CompleteAssignmentFailure(error))
      })
    )
  )

  adjustStudentGrade$: Observable<AdjustStudentGradeSuccess | AdjustStudentGradeFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<AdjustStudentGrade>(EAssignmentActions.AdjustStudentGrade),
      switchMap((action: AdjustStudentGrade) => {
        return this._schoolService.adjustStudentGrade(action.schoolId, action.classroomId, action.studentId, action.payload)
      }),
      exhaustMap((response: any) => of(new AdjustStudentGradeSuccess(response))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new AdjustStudentGradeFailure(error))
      })
    )
  )

  getStudentsGrades: Observable<GetStudentsGradesSuccess | GetStudentsGradesFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentsGrades>(EAssignmentActions.GetStudentsGrades),
      switchMap((action: GetStudentsGrades) => {
        return this._schoolService.getStudentsGrades(action.schoolId, action.classroomId)
      }),
      exhaustMap((response: any) => of(new GetStudentsGradesSuccess(response))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new GetStudentsGradesFailure(error))
      })
    )
  )

  deleteAssignment$: Observable<DeleteAssignmentFailure | DeleteAssignmentSuccess | RemoveAssignmentFromLesson> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteAssignment>(EAssignmentActions.DeleteAssignment),
      switchMap((action: DeleteAssignment) => {
        return this._schoolService.deleteAssignment(action.schoolId, action.classroomId, action.assignmentId).pipe(
          exhaustMap((response) => {
            return of(new DeleteAssignmentSuccess(response, action.assignmentId), new RemoveAssignmentFromLesson(action.assignmentId))
          }),
          catchError((error) => {
            this._notificationService.showNotificationError(error.error.clientMessage, 2)
            return of(new DeleteAssignmentFailure(error))
          })
        )
      })
    )
  )

  getStudentGrades: Observable<GetStudentGradesSuccess | GetStudentGradesFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStudentGrades>(EAssignmentActions.GetStudentGrades),
      switchMap((action: GetStudentGrades) => {
        return this._schoolService.getStudentGrades(action.schoolId, action.classroomId, action.studentId)
      }),
      exhaustMap((response: any) => of(new GetStudentGradesSuccess(response))),
      catchError((error) => {
        this._notificationService.showNotificationError(error.error.clientMessage, 2)
        return of(new GetStudentGradesFailure(error))
      })
    )
  )
}
