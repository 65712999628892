import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { select, Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { TestPlanComponent } from 'src/app/shared/popups/Lesson-Dialogs/test-plan/test-plan.component'
import { SetCurrentCourse } from 'src/app/store/actions/courses.actions'
import { selectCurrentLesson, selectCurrentPreference } from 'src/app/store/selectors/lessons.selectors'
import { downloadPdfLink, isIpad } from 'src/app/helpers/utils/LessonUtil/lessonTest.util'
import { ICourse } from '../../models/Course.model'
import { ISubuser } from '../../models/Subuser.model'
import { IUserComplex } from '../../models/User.model'
import { FormatTextService } from '../../services/formatText.service'
import { LessonsService } from '../../services/lessons.service'
import { PrintsService } from '../../services/prints.service'
import { AddAssignmentDialogComponent } from '../../shared/popups/Assignment-Dialogs/add-assignment/add-assignment.component'
import { EditAssignmentDialogComponent } from '../../shared/popups/Assignment-Dialogs/edit-assignment/edit-assignment.component'
import { LessonSubscribePopupComponent } from '../../shared/popups/Lesson-Dialogs/lesson-subscribe/lesson-subscribe.component'
import { DisplayHeaderLesson, GetLesson, GetLessons, HideHeaderLesson, SavePreference } from '../../store/actions/lessons.actions'
import { selectClassrooms } from '../../store/selectors/classrooms.selectors'
import { selectUnitsWithLessons } from '../../store/selectors/lessons.selectors'
import { selectCurrentSubuser } from '../../store/selectors/subuser.selectors'
import { selectCurrentUser } from '../../store/selectors/user.selectors'
import { IAppState } from '../../store/state/app.state'
import { isAccessleCourses, isAccessLesson } from '../../helpers/utils/LessonUtil/lesson.util'
import { rangomSpinner } from '../../helpers/utils/spiner.util'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"
import { GetClassrooms } from 'src/app/store/actions/classrooms.actions'
import { Router } from '@angular/router'
import { LessonUnavailablePopupComponent } from 'src/app/shared/popups/Lesson-Dialogs/lesson-unavailable/lesson-unavailable.component'
import { selectStudentAssignments } from 'src/app/store/selectors/assignments.selectors'
import { IAssignment } from 'src/app/models/Assignment.model'
@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
})
export class CurriculumComponent implements OnInit, OnDestroy {
  @Input() isDialog
  @Output() loaded = new EventEmitter<boolean>()
  @Output() close = new EventEmitter<boolean>()
  assignments: IAssignment[] = []

  constructor(
    private _store: Store<IAppState>,
    public translate: TranslateService,
    public testService: LessonsService,
    public _lessonsService: LessonsService,
    public _dialog: MatDialog,
    private _printService: PrintsService,
    private textService: FormatTextService,
    private elementRef: ElementRef,
    public subUserStoreService: SubUserStoreService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  public panelOpenState = false
  public selectedSubuser: ISubuser
  public selectedCourse: ICourse
  public units = []
  public subuserCurrent
  public userSubuser
  public courseCurrent = null
  public courseUnits
  public pagination = { pageSize: 10, currentPage: 0, totalItems: 0 }
  public pageSizes = [10, 50, 100]
  public prints
  public courseContentLength = 0
  public totalItems = null
  public totalPages = null
  public currentPage = null

  public totalActivities: number

  public user: IUserComplex
  public userType
  public type
  private unsubscribe$ = new Subject()

  public classrooms = []
  public selectedClassroom
  public virtualSubuser = null

  public userHasAccess = false
  public displayCourseId = 0
  public course: any
  public selectType = 'all';
  public unitName
  public data = false
  public isTeacherArea = false
  public loaderClass: string = rangomSpinner()
  isIpad = false

  public printLinkInactive = []

  public pdfLoading = false
  public pdfKeysAvailableLang = ['Spanish', 'French', 'German', 'Japanese', 'Korean', 'Polish', 'Portuguese - BR', 'Italian', 'English']

  public timerId

  @ViewChild('lessonsCont') lessonsCont: ElementRef
  @ViewChild('notFoundBlock') notFoundBlock: ElementRef

  ngOnInit() {
    this.data = false
    if (this.isDialog !== true) {
      this._store.dispatch(new HideHeaderLesson())
    }
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentPreference)).subscribe((selector) => {
      if (selector) {
        const { selectType, page } = selector;
        this.selectType = selectType;
        this.pagination.currentPage = page;
      }
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectStudentAssignments)).subscribe((res) => {
      this.assignments = res
    })

    this.selectUnitLessons()
    this.getLessonPlanContent()
  }

  openTestPlanPopup(lesson) {
    this._dialog.open(TestPlanComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: lesson,
    })
  }

  public formatLangName(lang) {
    return this.textService.formatLangName(lang)
  }

  public formatCourseName(course) {
    return this.textService.formatCourseName(course)
  }

  public formatMultiName(course) {
    return this.textService.formatMultiName(course)
  }

  ngOnDestroy() {
    clearTimeout(this.timerId)

    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  get subuserId() {
    return this.subuserCurrent.id
  }

  get courseId() {
    if (['teacherAdmin', 'teacher'].includes(this.userType)) {
      if (!this.selectedClassroom) {
        return null
      }

      return this.selectedClassroom.courseId
    }
    if (this.courseCurrent) {
      return this.courseCurrent.id
    }

    return this.subuserCurrent.courses[0].id
  }

  get courseName() {
    if (this.type === 'school') {
      return this.selectedClassroom.classroomName
    }

    return this.courseCurrent.name
  }

  get closeRoute() {
    if (this.type === 'school') {
      return '/profile/teachers/students'
    }

    return '/profile/lessons'
  }

  /**
   * GET LESSON CONTENT BY TYPE
   * @param lessonType
   */
  public getLessonsByType(lessonsType?) {
    const request: any = {
      courseId: this.courseId,
      size: this.pagination.pageSize,
      ...(lessonsType && { lessonsType }),
      page: 0
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }
    if (['parent', 'student', 'admin'].includes(this.userType)) {
      request.subuserId = this.subuserId ? this.subuserId : this.virtualSubuser.id
    }

    this._store.dispatch(new GetLessons(request))
  }
  /**
   * GET LESSONS CONTENT BY CURRENT CHILD
   */
  getLessonPlanContent() {
    this._store
      .select(selectCurrentUser)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((user) => !!user),
        mergeMap((user) => {
          if (['teacherAdmin', 'teacher'].includes(user.userType)) {
            this._store.dispatch(new GetClassrooms(user.user.schoolId))

            return this._store.select(selectClassrooms).pipe(
              takeUntil(this.unsubscribe$),
              map((classrooms) => {
                return {
                  user,
                  classrooms: classrooms.classrooms,
                  type: 'school',
                }
              })
            )
          }
          return this._store.select(selectCurrentSubuser).pipe(
            takeUntil(this.unsubscribe$),
            map((subuser) => {
              return {
                user,
                subuser,
                type: 'parent',
              }
            })
          )
        })
      )
      .subscribe((res: any) => {
        this.user = res.user
        this.userType = this.user.userType
        this.type = res.type
        if (this.type === 'school') {
          if (!res.classrooms.length) {
            return
          }

          this.classrooms = res.classrooms
          this.selectedClassroom = res.classrooms[0]
          if (res.user.user.subusers.length > 0) {
            this.virtualSubuser = res.user.user.subusers[0]
          }
        } else {
          this.userSubuser = res.user.user.subusers
          if (this.isDialog || this.userType == 'student') {
            this.subuserCurrent = res.subuser
            this.courseCurrent = this._getSubuserCurrentCourse(res.subuser)
          } else {
            const currentSubuser = JSON.parse(this.subUserStoreService.currentSubuser)
            this.userSubuser.forEach((el, i) => {
              if (el.id == currentSubuser.id) {
                this.subuserCurrent = this.userSubuser[i]
                return
              }
            })
            const localDataCourse = this._getSubuserCurrentCourse(res.subuser)
            this.subuserCurrent.courses.forEach((el, i) => {
              if (localDataCourse.id == el.id) {
                this.courseCurrent = this.subuserCurrent.courses[i]
                return
              }
            })
          }
        }

        this.setTeacherAreaFlag();
        this.getLessonsContent(this.selectType)

        const course = {
          id: this.courseId,
        }

        this.userHasAccess = isAccessleCourses(this.user.accessibleCourses, course)
      })
  }

  private _getSubuserCurrentCourse(subuser) {
    if (subuser.id.toString() in this.subUserStoreService.subuserCurrentCourse) {
      return JSON.parse(this.subUserStoreService.subuserCurrentCourse[subuser.id.toString()])
    }

    return subuser.courses[0]
  }

  getLessonsContent(lessonsType, initial = true) {
    this.selectType = lessonsType

    const request: any = {
      courseId: this.courseId,
      size: this.pagination.pageSize,
      ...(lessonsType && { lessonsType })
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    const currentPage = this.pagination.currentPage;

    if (!initial) {
      request.page = currentPage - 1;
    }

    if (['parent', 'student', 'admin'].includes(this.userType)) {
      request.subuserId = this.subuserId ? this.subuserId : this.virtualSubuser.id
    }

    this._store.dispatch(new GetLessons(request))

  }

  selectUnitLessons() {
    this.data = false
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectUnitsWithLessons))
      .pipe(filter((data) => data !== null))
      .subscribe((data) => {
        this.pagination.currentPage = data.currentPage + 1;
        this.pagination.totalItems = data.totalItems;
        this.courseUnits = data.items;

        const currentLessonElement = this.elementRef.nativeElement.querySelector('.unitContent.current')
        if (currentLessonElement)
          currentLessonElement.scrollIntoView({ behavior: 'smooth' })

        this._rebuildUnits(this.courseUnits)
        this.totalActivities = 0
        data.items.forEach((element) => {
          this.totalActivities += element.lessons.length
          element.lessons.forEach((lesson) => {
            if (lesson.prints) {
              this.totalActivities += lesson.prints.length
            }
          })
        })

        this.data = true
        if (this.isDialog === true) {
          this.loaded.emit(true)
        }
      })
  }

  handlePageChange(event) {
    this.data = false;
    const request: any = {
      courseId: this.courseId,
      page: event - 1,
      lessonsType: this.selectType
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    if (['parent', 'student', 'admin'].includes(this.userType)) {
      request.subuserId = this.subuserId ? this.subuserId : this.virtualSubuser.id
    }

    this._store.dispatch(new GetLessons(request))
  }

  handlePageSizeChange(value) {
    const request: any = {
      courseId: this.courseId,
      size: this.pagination.pageSize,
      lessonsType: this.selectType
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    if (['parent', 'student', 'admin'].includes(this.userType)) {
      request.subuserId = this.subuserId ? this.subuserId : this.virtualSubuser.id
    }

    this._store.dispatch(new GetLessons(request))
  }

  handleSearch(value?) {
    const request: any = {
      courseId: this.courseId,
      size: this.pagination.pageSize,
      lessonsType: this.selectType,
      page: 0
    }

    if (value) {
      request.search = value
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    if (['parent', 'student', 'admin'].includes(this.userType)) {
      request.subuserId = this.subuserId ? this.subuserId : this.virtualSubuser.id
    }

    this._store.dispatch(new GetLessons(request))
  }

  private _rebuildUnits(units) {
    if (!units.length) {
      return
    }

    units.forEach((unit, a) => {
      this.units.push({
        name: unit.name,
        groups: [[]],
      })

      unit.lessons.forEach((lesson, b) => {
        if (lesson.typeId === 3 || lesson.typeId === 4) {
          this.units[a].groups[this.units[a].groups.length - 1].push(lesson)
          if (b < unit.lessons.length - 1) {
            this.units[a].groups.push([])
          }
        } else {
          this.units[a].groups[this.units[a].groups.length - 1].push(lesson)
        }
      })
    })
  }

  public showLessonPlan() {
    this.subUserStoreService.subuserCurrentCourse[`${this.subuserCurrent.id}`] = JSON.stringify(this.courseCurrent)
    this._store.dispatch(new SetCurrentCourse(this.courseCurrent))
    this.pagination.currentPage = 1;
    this.getLessonsContent(this.selectType, false)
  }

  /**
   * GET ALL PRINTS BY LESSONS
   */
  getPrints() {
    const request: any = {
      printName: null,
      subuserId: this.subuserId,
      courseId: this.courseId,
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    this._lessonsService.getPrints(request).subscribe((prints) => {
      this.prints = prints
      this.totalActivities = this.prints.count
    })
  }

  /**
   * REDIRECT TO LESSON IF USER HAS ACCESS TO THIS LESSON
   * @param lesson
   */
  goToLesson(lesson) {
    if (!lesson.isFree || !this.userHasAccess) {
      this._dialog.open(LessonSubscribePopupComponent, {})
    }
  }

  /**
   * SAVE PROGRESS PRINT MATERIAL
   * @param printId
   * @param repeat
   */
  completePrint(printId, repeat) {
    const request = {
      subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
      printId,
      repeat: repeat.length == 0 ? 1 : repeat[0].repeat + 1,
    }

    this._printService.completedPrint(request).subscribe(() => {
      if (this.selectType === 'prints') {
        return this.getPrints()
      }

      this.getLessonsContent(this.selectType, false)
    })
  }

  selectClassroom(classroom) {
    this.selectedClassroom = classroom

    this.getLessonsContent(this.selectType, false)
  }

  openAddAssignmentDialog(lesson) {
    this._dialog.open(AddAssignmentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.selectedClassroom.schoolId,
        classroomId: this.selectedClassroom.id,
        lessonId: lesson.id,
      },
    })
  }

  openEditAssignmentDialog(assignment) {
    this._dialog.open(EditAssignmentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.selectedClassroom.schoolId,
        classroomId: this.selectedClassroom.id,
        assignment,
      },
    })
  }

  closeCurriculum() {
    this.close.emit(true)
  }

  downloadPdf(lesson, index) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DOWNLOAD_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      this.openPrint(lesson, index)
    })
  }

  public getPDFKeywords() {
    if (!this.userHasAccess) {
      this._dialog.open(LessonSubscribePopupComponent, {})
      return
    }
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DOWNLOAD_HEAVY_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      let slug = `printed/${this.courseCurrent.name.replace(/\s/g, '')}/Worksheets/${this.courseCurrent.name.toUpperCase().replace(/\s/g, '')}-ANSWER-KEY-1.pdf`
      this.pdfLoading = true
      this._printService.getPrintPDF(slug).subscribe((pdf) => {
        downloadPdfLink(pdf, `${this.courseCurrent.name} Worksheets - Answer Keys.pdf`, false, isIpad())
        this.pdfLoading = false
      })
    })
  }

  openPrint(lesson, index) {
    if (this.printLinkInactive[index]) {
      return
    }
    this.printLinkInactive[index] = true

    const src = lesson.questions[0].src.match(/printed[^\.]+\.pdf/gi)
    this._printService.getPrintPDF(src).subscribe((pdf) => {
      // openPdfLink(pdf);

      downloadPdfLink(pdf, `${lesson.name}.pdf`, false, isIpad())
      this.printLinkInactive = []
    })

    // window.open(lesson.questions[0].src, '_blank');
    const progress = 100
    const repeats = lesson.completed ? lesson.completed.repeat : 0
    const request = {
      subuserStars: 0,
      subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
      lessonId: lesson.id,
      progress,
      courseId: this.courseId,
      dinosaurId: null,
      repeat: repeats + 1,
    }
    this._lessonsService.completeLesson(request).subscribe(() => {
      this.getLessonsContent(this.selectType, false)
    })
  }

  openPrintOld(printId) {
    this._store.dispatch(
      new GetLesson({
        lessonId: printId,
        subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
      })
    )
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentLesson)).subscribe((lesson) => {
      if (lesson) {
        window.open(lesson.questions[0].src, '_blank')
        const progress = 100
        const repeats = lesson.completed ? lesson.completed.repeat : 0
        const request = {
          subuserStars: 0,
          subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
          lessonId: printId,
          progress,
          courseId: this.courseId,
          dinosaurId: null,
          repeat: repeats + 1,
        }
        this._lessonsService.completeLesson(request).subscribe(() => {
          this.getLessonsContent(this.selectType, false)
        })
      }
    })
  }

  savePreference(lesson) {
    const dataForCheck = {
      lesson,
      accessibleCourses: this.user.accessibleCourses,
      subuser: this.subuserCurrent,
      id: this.courseId,
    }

    if (!this.isTeacherArea) {
      const isAccess = isAccessLesson(dataForCheck)
      const isAssignment = this.subuserCurrent.studentId && this.assignments.some((a: IAssignment) => a.lessonId === lesson.id)

      if (isAccess === true || isAssignment === true) {
        this.router.navigate([`/profile/lesson/${lesson.id}/${lesson.type.slug}`], { state: { isFromCurriculum: !this.isDialog } })
      } else {
        this.openUnavailableLesson()
      }
    } else {
      this.router.navigate([`/profile/lesson/${lesson.id}/${lesson.type.slug}`], { state: { isFromCurriculum: !this.isDialog } })
    }

    this._store.dispatch(new SavePreference(this.selectType, this.pagination.currentPage))
    
    if (this.isDialog !== true) {
      this._store.dispatch(new DisplayHeaderLesson())
    }
  }

  setTeacherAreaFlag() {
    if (['teacherAdmin', 'teacher'].includes(this.userType))
      this.isTeacherArea = true;
  }

 
  public openUnavailableLesson() {
    this.dialog.open(LessonUnavailablePopupComponent, {
      hasBackdrop: true,
    })
  }
}
