import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ActivatedRoute } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { IClassroom } from '../../../../models/Classroom.model'
import { ISchool } from '../../../../models/School.model'
import { IStudent } from '../../../../models/Student.model'
import { AdjustGradeDialogComponent } from '../../../../shared/popups/Assignment-Dialogs/adjust-grade/adjust-grade.component'
import { ExportGradebookDialogComponent } from '../../../../shared/popups/Export-Dialogs/export-gradebook/export-gradebook.component'
import { GetStudentsAssignments, GetStudentsGrades } from '../../../../store/actions/assignment.actions'
import { GetClassrooms } from '../../../../store/actions/classrooms.actions'
import { EStudentActions, GetStudents, GetStudentsSuccess } from '../../../../store/actions/students.actions'
import { selectStudentsAssignments, selectStudentsGrades } from '../../../../store/selectors/assignments.selectors'
import { selectClassrooms } from '../../../../store/selectors/classrooms.selectors'
import { selectStudents } from '../../../../store/selectors/students.selectors'
import { IAppState } from '../../../../store/state/app.state'

@Component({
  selector: 'app-gradebook',
  templateUrl: './gradebook.component.html',
  styleUrls: ['./gradebook.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GradebookComponent implements AfterContentInit, OnDestroy {
  constructor(
    private _store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private _actions$: Actions,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialog: MatDialog
  ) { }

  private unsubscribe$ = new Subject()

  private school: ISchool
  public loaded = false
  public classrooms: Array<IClassroom> = []
  public selectedClassroom
  public students: Array<IStudent> = []
  public assignments: any = []
  public grades: any = []
  getAllstudents: IStudent[]

  ngAfterContentInit(): void {
    this.loadClassrooms()
    this.loaded = true
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  loadClassrooms() {
    this.activatedRoute.parent.data.pipe(switchMap((res) => res.school)).subscribe((school: ISchool) => {
      this.school = school
      this.loadStudents()
      if (this.classrooms)
        this._store.dispatch(new GetClassrooms(school.id))

      this._actions$.pipe(
        ofType<GetStudentsSuccess>(EStudentActions.GetStudentsSuccess),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this._store.pipe(
          select(selectClassrooms),
          takeUntil(this.unsubscribe$),
          filter((res) => !!res.classrooms.length),
          map((res) => res.classrooms)
        ).subscribe((classrooms: Array<IClassroom>) => {
          this.classrooms = classrooms;

          if (classrooms.length) {
            this.loadGrades(classrooms[0].id)
            this.selectClassroom(classrooms[0])
          }
        });
      });


      this._store
        .pipe(
          takeUntil(this.unsubscribe$),
          select(selectStudentsAssignments),
          filter((assignments) => !!assignments && assignments.hasOwnProperty('lessons'))
        )
        .subscribe(
          (assignments: any) => {
            this.assignments = assignments
            this.loaded = true
            this._changeDetectorRef.detectChanges()
          },
          () => {
            this.loaded = true
            this._changeDetectorRef.detectChanges()
          }
        )
    })
  }


  loadStudents() {
    this._store.dispatch(new GetStudents(this.school.id))
    this._actions$.pipe(ofType<GetStudentsSuccess>(EStudentActions.GetStudentsSuccess), takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.getAllstudents = res.students;
    })
  }

  loadGrades(classroomId) {
    this._store.dispatch(new GetStudentsGrades(this.school.id, classroomId))
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectStudentsGrades)).subscribe((grades: any) => {
      this.grades = grades
      this._changeDetectorRef.detectChanges()
    })
  }

  loadAssignments(classroomId, offset = 0) {
    this._store.dispatch(new GetStudentsAssignments(this.school.id, classroomId, 0, 0))
  }

  selectClassroom(classroom) {
    this.loaded = false
    this.selectedClassroom = classroom
    this.assignments = {}
    this.getStudentsForClassroom(classroom.id)
    this.loadAssignments(classroom.id)
  }

  getStudentsForClassroom(classroomId) {
    this.students = this.getAllstudents.filter((student) => student.classroomId === classroomId)
  }

  getResultCssClass(completed, lessonType) {
    let cssClass
    if (completed === undefined) {
      cssClass = 'incomplete'
    } else {
      if ([3, 4, 5].includes(lessonType)) {
        if (completed.progress >= 80) {
          cssClass = 'complete'
        } else {
          cssClass = 'complete low-score'
        }
      } else {
        if (completed?.adjustedProgress === 0) {
          cssClass = 'incomplete'
        } else {
          cssClass = 'complete'
        }
      }
    }

    if (completed && completed.hasOwnProperty('adjustedProgress')) {
      cssClass += ' adjusted'
    }

    return cssClass
  }

  getOverallGrade(student: IStudent): number {
    const grade = this.grades.find((g) => g.student.id === student.id)
    if (grade) {
      return grade.overallGrade
    }

    return 0
  }

  openExportGradebookDialog(student: IStudent = null) {
    this._dialog.open(ExportGradebookDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        classroom: this.selectedClassroom,
        student,
      },
    })
  }

  openAdjustGradeDialog(assignmentCompleted: any, student: IStudent) {
    const dialog = this._dialog.open(AdjustGradeDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '32rem',
      data: {
        assignmentCompleted,
        student,
      },
    })

    dialog.afterClosed().subscribe()
  }

  get tableData() {
    const data = []

    if (this.assignments.lessons?.length) {
      this.assignments.lessons.forEach((lesson) => {
        const results = []

        this.students.forEach((student) => {
          const completed = lesson.completed.find((c) => c.studentId === student.id)
          let result: any = {}

          if (completed !== undefined) {
            result = { ...completed }
            if (completed.adjustedProgress) {
              result.progress = completed.adjustedProgress
            }
            result.isAdjusted = completed.adjustedProgress > 0
          }

          result.lesson = lesson
          result.complete = completed !== undefined
          result.cssClass = this.getResultCssClass(completed, lesson.typeId)
          result.lessonType = lesson.typeId

          if (![3, 4, 5].includes(lesson.typeId)) {
            // check if the assignment was marked
            // incomplete by a teacher
            if (completed?.adjustedProgress === 0) {
              result.complete = false
            }
          }

          result.student = student
          results.push(result)
        })

        data.push({
          lesson,
          results,
        })
      })
    }

    return data
  }
}
