<div class="addClassroom">
  <div class="actions">
    <span class="title">{{ 'CLASSROOM.CREATE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="content">
    <div class="form" *ngIf="school.allocableStudents > 0; else elseBlock">
      <form [formGroup]="classroomForm">
        <mat-form-field class="name">
          <mat-label>{{ 'CLASSROOM.NAME' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.CLASSROOM_NAME' | translate }}" required formControlName="classroomName" />
          <mat-hint *ngIf="classroomForm.controls.classroomName.invalid && (classroomForm.controls.classroomName.dirty || classroomForm.controls.classroomName.touched)">
            <mat-error>{{ 'CLASSROOM.INVALID_NAME' | translate }} </mat-error>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="classroomCode">
          <mat-label>{{ 'CLASSROOM.CODE' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.CLASSROOM_CODE_AUTOGENERATE' | translate }}" formControlName="classroomCode" />
          <mat-hint>{{ 'PLACEHOLDERS.CLASSROOM_CODE_AUTOGENERATE' | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field class="allocatedStudents">
          <mat-label>{{ 'CLASSROOM.ALLOCATED_STUDENTS' | translate }} </mat-label>
          <input matInput placeholder="{{ 'PLACEHOLDERS.CLASSROOM_ALLOCATED_STUDENTS' | translate }}" required formControlName="allocatedStudents" />
          <mat-hint>{{ 'CLASSROOM.HELP.ALLOCABLE_STUDENT_RANGE' | translate }} {{ allocableStudentRange }}</mat-hint>
        </mat-form-field>
        <mat-form-field class="input">
          <mat-label>{{ 'CLASSROOM.COURSE' | translate }}</mat-label>
          <mat-select formControlName="courseId" required>
            <ng-container *ngFor="let course of courses">
              <mat-option [value]="course.id">{{ course.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input">
          <mat-label>{{ 'CLASSROOM.TEACHER' | translate }}</mat-label>
          <mat-select formControlName="teacher" required>
            <ng-container *ngFor="let teacher of teachers">
              <mat-option [value]="teacher.id">{{ teacher.firstName }} {{ teacher.lastName }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div class="blockLessons">
          <div class="slider">
            <mat-slide-toggle formControlName="lessonMode">{{ 'CHILD.UNLOCK' | translate }}</mat-slide-toggle>

            <span (click)="changeToolTip($event)" class="tooltip-btn">
              <img class="audio" src="/assets/images/games/questionmar.png" alt="play-back-sound test" />
            </span>
          </div>
          <div class="tooltip" *ngIf="displayTooltip">
            <span>{{ 'CHILD.LOCK' | translate }}</span>
          </div>
        </div>
      </form>
    </div>
    <ng-template #elseBlock>
      <div class="no-student-slots">
        {{ 'CLASSROOM.NO_STUDENT_SLOTS' | translate }}
      </div>
    </ng-template>
  </div>

  <div class="buttons" *ngIf="school.allocableStudents > 0; else buttonElseBlock">
    <button mat-raised-button color="accent" [disabled]="!classroomForm.valid" (click)="createClassroom(classroomForm)">
      {{ 'CLASSROOM.CREATE' | translate }}
    </button>
  </div>
  <ng-template #buttonElseBlock>
    <button mat-raised-button color="accent" (click)="dialogRef.close()">
      {{ 'MODAL.CANCEL' | translate }}
    </button>
  </ng-template>
</div>
