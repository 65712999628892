import { Component, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent {
  trustedHtml: any;
  constructor(
    public dialogRef: MatDialogRef<NotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer
    ) {
      this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(data.message);
    }

  close() {
    this.dialogRef.close()
  }
}
