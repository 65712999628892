import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { VideoPlayerComponent } from './video-player.component'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { FeatureEnabledDirective } from 'src/app/helpers/directives/feature/feature-enabled.directive'

@NgModule({
  declarations: [VideoPlayerComponent],

  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSelectModule,
    FeatureEnabledDirective
  ],
  exports: [VideoPlayerComponent],
})
export class VideoPlayerModule {}
