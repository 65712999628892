<div class="notific">
  <div class="main">
    <div class="title">{{ data.title }}</div>
    <div class="content">
      <div class="text" [innerHTML]="trustedHtml"></div>
    </div>
  </div>
  <div class="second" *ngIf="!data.emergency">
    <button mat-raised-button color="accent" (click)="close()">OK</button>
  </div>
</div>
