import { createSelector } from '@ngrx/store'
import { IAppState } from '../state/app.state'
import { IStudentState } from '../state/students.state'

const studentState = (state: IAppState) => state.student

export const selectStudents = createSelector(studentState, (state: IStudentState) => {
  return state.students
})

export const selectCurrentStudent = createSelector(studentState, (state: IStudentState) => {
  return state.currentStudent
})
