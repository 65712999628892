import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AddClassroom, AddClassroomSuccess, EClassroomActions } from '../../../../store/actions/classrooms.actions'
import { IAppState } from '../../../../store/state/app.state'
import { ISchool } from 'src/app/models/School.model'

@Component({
  templateUrl: './add-classroom.component.html',
  styleUrls: ['./add-classroom.component.scss'],
})
export class AddClassroomDialogComponent implements OnInit, OnDestroy {
  public classroomForm: UntypedFormGroup
  public courses
  public teachers
  private destroyed$ = new Subject<void>()
  public displayTooltip = false
  public school: ISchool

  constructor(private updates$: Actions, private _dialog: MatDialog, public dialogRef: MatDialogRef<AddClassroomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<IAppState>, public translate: TranslateService) {
    this.updates$.pipe(ofType<AddClassroomSuccess>(EClassroomActions.AddClassroomSuccess), takeUntil(this.destroyed$)).subscribe((res: AddClassroomSuccess) => {
      this.dialogRef.close(res)
    })
  }

  ngOnInit() {
    const { courses, teachers, user, school } = this.data;
    this.courses = courses;
    this.teachers = teachers;
    this.school = school;
    this.classroomForm = new UntypedFormGroup({
      teacher: new UntypedFormControl('', [Validators.required]),
      schoolId: new UntypedFormControl('', [Validators.required]),
      classroomName: new UntypedFormControl('', [Validators.required]),
      classroomCode: new UntypedFormControl({ value: '', disabled: true }),
      courseId: new UntypedFormControl('', [Validators.required]),
      allocatedStudents: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(this.school.allocableStudents)]),
      lessonMode: new UntypedFormControl(''),
    })

    this.classroomForm.patchValue({
      schoolId: user.schoolId,
    })
  }

  ngOnDestroy() {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  createClassroom(form: UntypedFormGroup) {
    if (form.invalid) {
      return
    }
    const request = form.value
    this._store.dispatch(new AddClassroom(request))
  }

  get allocableStudentRange() {
    const max = Math.max(0, this.school.allocableStudents)
    return `1 - ${max}`
  }

  public changeToolTip(event: Event) {
    this.displayTooltip = !this.displayTooltip
    event.stopPropagation()
  }
}
