import { Component, OnInit, OnDestroy } from '@angular/core'
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { Store } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { EAuthActions, SignIn, SignInFailure, SignInSuccess } from 'src/app/store/actions/auth.actions'
import { IUserLogin } from 'src/app/models/User.model'
import { DeviceDetectorService } from 'ngx-device-detector'
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { LoginStudentComponent } from '../login-student/login-student.component'
import { FormatTextService } from '../../../services/formatText.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { SEOService } from 'src/app/services/seo.service'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntil, take } from 'rxjs/operators'
import { Subject } from 'rxjs'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    private deviceService: DeviceDetectorService,
    public translate: TranslateService,
    public _dialog: MatDialog,
    private textService: FormatTextService,
    private route: ActivatedRoute,
    private _router: Router,
    private localizeHelperService: LocalizeHelperService,
    private _seoService: SEOService,
    private _actions$: Actions,
    private authService: SocialAuthService,
  ) { }

  public deviceInfo = this.deviceService.getDeviceInfo()
  public loginFormGroup: UntypedFormGroup
  public currentLan: string
  public hidePassword = true
  public lURL
  public langParam = this.route.parent.snapshot.params && this.route.parent.snapshot.params.lang ? this.route.parent.snapshot.params.lang : ''
  public loading = false
  public unsubscribe$ = new Subject()
  public processWithGoogle = false
  public appleTokenId = null

  ngOnInit() {
    // Apple Sign-In
    // Listen for authorization success.
    document.addEventListener('AppleIDSignInOnSuccess', (event: CustomEvent) => {
      // Handle successful response.
      console.log('AppleIDSignInOnSuccess', event);

      const request = {
        email: 'fake@fake.com',
        appleCode: event.detail.authorization.code,
        appleTokenId: event.detail.authorization.id_token,
        os: this.deviceInfo.os,
        browser: this.deviceInfo.browser,
        userAgent: this.deviceInfo.userAgent,
      }

      this.store.dispatch(new SignIn(request))

      this.appleTokenId = event.detail.authorization.id_token
    });

    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      // Handle error.
      console.log('AppleIDSignInOnFailure', event);
    });

    // Subscribe to both success and failure actions in a single stream
    this._actions$
      .pipe(
        ofType(EAuthActions.SignInSuccess, EAuthActions.SignInFailure), // Listen to both success and failure actions
        takeUntil(this.unsubscribe$) // Ensures the subscription is properly cleaned up
      )
      .subscribe((result: Array<string> | SignInSuccess | SignInFailure) => {
        if (result instanceof SignInSuccess) {
          // Handle success action
          console.log('login success. response:', result);
        } else if (result instanceof SignInFailure) {
          // Handle failure action
          console.log('login failed. response', result);

          if (this.processWithGoogle) {
            this._router.navigate(['/auth/registration']);
          } else if (this.appleTokenId) {
            this._router.navigate(['/auth/registration'], {
              queryParams: {
                code: 'fake code', // no needed for now
                id_token: this.appleTokenId,
              }
            });
          }
        }
        this.loading = false
      });

    // GOOGLE LOGIN
    this.authService.authState.pipe(
      takeUntil(this.unsubscribe$) // Ensures the subscription is properly cleaned up
    ).subscribe((user) => {
      if (!user || this.processWithGoogle) {
        return; // Prevent re-processing if already handled or no user data
      }

      const request = {
        email: user.email,
        googleTokenId: user.idToken,
        os: this.deviceInfo.os,
        browser: this.deviceInfo.browser,
        userAgent: this.deviceInfo.userAgent,
        firstName: user.firstName,
        lastName: user.lastName
      }

      this.store.dispatch(new SignIn(request))

      this.processWithGoogle = true
    })
    // GOOGLE LOGIN END

    const { meta } = this.route.snapshot.data

    this.currentLan = this.localizeHelperService.getLangForced()

    this.lURL = this.localizeHelperService.getURLLang()

    const localMeta = meta[this.currentLan] == undefined ? meta['en'] : meta[this.currentLan]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this.localizeHelperService.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lURL = this.localizeHelperService.getURLLang()
      this.currentLan = event.lang
      this._seoService.updateTitle(localMeta.title)
      this._seoService.updateDescription(localMeta.description)
      this._seoService.createCanonicalLink(localMeta.canonical)
    })

    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      os: ['', Validators.required],
      browser: ['', Validators.required],
      userAgent: ['', Validators.required],
    })
    this.loginFormGroup.patchValue({
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      userAgent: this.deviceInfo.userAgent,
    })
  }

  onSocialLoginFeature(state: boolean): void {
    if (!state) { return };

    // Initialize Apple Sign-In button
    AppleID.auth.init({
      clientId: 'com.dinolearning.dinolearning.dev.service', // Your Service ID
      // scope: 'name email', // doesn't work with popup mode
      redirectURI: 'https://dev-learn.dinolearning.com/auth/login',
      usePopup: true,
      // responseMode: 'form_post', // required for non-popup mode
    });
    // Apple Sign-In END
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  public login(formGroup: UntypedFormGroup) {
    this.loading = true

    const request: IUserLogin = formGroup.value
    // USER LOGIN
    this.store.dispatch(new SignIn(request));
  }

  get email() {
    return this.loginFormGroup.get('email')
  }

  get password() {
    return this.loginFormGroup.get('password')
  }

  studentLogin() {
    this._dialog.open(LoginStudentComponent, {
      width: '22rem',
      height: '22rem',
    })
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
