<section class="subuser">
  <article class="contentWrapper">
    <div class="title">{{ 'LINKS.TEACHER_DASHBOARD' | translate }}</div>
    <ng-container *ngIf="loaded">
      <div [ngClass]="{ 'classroom-controls': user.userType !== 'teacherAdmin', 'classroom-controls-2': user.userType === 'teacherAdmin' }">
        <button type="button" class="btn-add-teacher" (click)="openAddTeacherDialog()" *ngIf="user.userType === 'teacherAdmin'">
          <img src="assets/images/icons/green-plus.svg" alt="add teacher" />
          <span>1. {{ 'TEACHER.ADD_NEW_TEACHER' | translate }}</span>
        </button>
        <button type="button" class="btn-add-classroom" (click)="openAddClassroomDialog()" *ngIf="user.userType === 'teacherAdmin'">
          <img src="assets/images/icons/green-plus.svg" alt="add classroom" />
          <span>2. {{ 'CLASSROOM.ADD_NEW_CLASSROOM' | translate }}</span>
        </button>
        <button type="button" class="btn-add-student" (click)="openAddStudentDialog()">
          <img src="assets/images/icons/green-plus.svg" alt="add student" />
          <span> <span *ngIf="user.userType === 'teacherAdmin'">3. </span>{{ 'STUDENT.ADD_NEW_USER' | translate }} </span>
        </button>
      </div>
      <div class="classroom-division" *ngFor="let classroom of classrooms">
        <div class="classroom-header">
          <h3 class="classroom-name">
            <div class="class-name-edit-delete">
              {{ classroom.classroomName }}
              <img (click)="openEditClassroomDialog(classroom)" class="icon-edit-classroom" src="assets/images/icons/edit.svg" alt="edit classroom" />
              <img (click)="openDeleteClassroomDialog(classroom)" class="icon-delete-classroom" src="assets/images/icons/redClose.svg" alt="delete classroom" *ngIf="user.userType === 'teacherAdmin'" />
            </div>

            <button type="button" class="btn-bulk-add-students" (click)="goToBulkAddStudents(classroom)">
              <img src="assets/images/icons/green-plus.svg" alt="bulk add students" />
              <span>{{ 'STUDENT.BULK_ADD_STUDENTS' | translate }}</span>
            </button>
            <button type="button" class="btn-print-student-list" (click)="showPdf(classroom)">
              <img src="assets/images/icons/download.svg" alt="print student list" />
              <span>{{ 'STUDENT.PRINT_STUDENT_LIST' | translate }}</span>
            </button>
          </h3>
          <div class="classroom-info">
            <span class="classroom-code">
              {{ 'CLASSROOM.CODE' | translate }}: <span class="font-normal">{{ classroom.classroomCode.toLowerCase() }}</span>
            </span>
            <span class="classroom-teacher">
              {{ 'CLASSROOM.TEACHER' | translate }}: <span class="font-normal">{{ classroom.teacher.firstName }} {{ classroom.teacher.lastName }}</span>
            </span>
            <span class="classroom-course">
              {{ 'CLASSROOM.COURSE' | translate }}: <span class="font-normal">{{ classroom.course.name }}</span>
            </span>
            <span class="classroom-num_students">
              {{ 'CLASSROOM.NUM_STUDENTS' | translate }}: <span class="font-normal">{{ classroom.numStudents }} / {{ classroom.allocatedStudents }}</span>
            </span>
          </div>
        </div>
        <app-list-students [schoolId]="classroom.schoolId" [classroomId]="classroom.id" [students]="classroom.students"></app-list-students>
      </div>
    </ng-container>
  </article>
</section>
