import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IClassroom } from '../../../../models/Classroom.model'
import { ISchool } from '../../../../models/School.model'
import { EClassroomActions, UpdateClassroom, UpdateClassroomSuccess } from '../../../../store/actions/classrooms.actions'
import { IAppState } from '../../../../store/state/app.state'

@Component({
  templateUrl: './edit-classroom.component.html',
  styleUrls: ['./edit-classroom.component.scss'],
})
export class EditClassroomDialogComponent implements OnInit, OnDestroy {
  public classroom: IClassroom
  public school: ISchool
  public classroomForm: UntypedFormGroup
  public courses
  private destroyed$ = new Subject<boolean>()
  public displayTooltip = false
  public unlocked: boolean;

  constructor(private updates$: Actions, private _dialog: MatDialog, public dialogRef: MatDialogRef<EditClassroomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<IAppState>, public translate: TranslateService) {
    this.classroom = data.classroom
    this.school = data.school
    this.unlocked = data.classroom.lessonMode
    this.courses = data.courses

    this.updates$.pipe(ofType<UpdateClassroomSuccess>(EClassroomActions.UpdateClassroomSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateClassroomSuccess) => {
      this.dialogRef.close(res)
    })
  }

  ngOnInit() {
    this.classroomForm = new UntypedFormGroup({
      classroomName: new UntypedFormControl(this.classroom.classroomName, [Validators.required]),
      classroomCode: new UntypedFormControl({
        value: this.classroom.classroomCode.toLowerCase(),
        disabled: true,
      }),
      regenerateClassroomCode: new UntypedFormControl(''),
      courseId: new UntypedFormControl(this.classroom.courseId, [Validators.required]),
      allocatedStudents: new UntypedFormControl(this.classroom.allocatedStudents, [Validators.min(Math.max(1, this.classroom.numStudents)), Validators.max(this.classroom.allocatedStudents + this.school.allocableStudents)]),
      lessonMode: new UntypedFormControl(this.classroom.lessonMode),
    })
    // Subscribe to the value changes
    this.classroomForm.get('lessonMode')?.valueChanges.subscribe((newValue) => {
      this.unlocked = newValue;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  updateClassroom(form: UntypedFormGroup) {
    if (form.invalid) {
      return
    }
    const request = form.value
    this._store.dispatch(new UpdateClassroom(this.school.id, this.classroom.id, request))
  }

  get allocableStudentRange() {
    const min = Math.max(1, this.classroom.numStudents)
    const max = this.classroom.allocatedStudents + this.school.allocableStudents
    return `${min} - ${max}`
  }

  public changeToolTip(event: Event) {
    this.displayTooltip = !this.displayTooltip
    event.stopPropagation()
  }
}
