import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { SiteStatusService } from './services/siteStatus.service'
import { SubUserStoreService } from "./store/services/subuser-store.service"
import { LocalizeHelperService } from "./services/localizHelper.service"
import { NotificService } from 'src/app/services/notific.service'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { NotificationsComponent } from 'src/app/shared/components/notifications/notifications.component'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  static isBrowser = new BehaviorSubject<boolean>(null)

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,
    public siteStatusService: SiteStatusService,
    public localizeHelperService: LocalizeHelperService,
    public subUserStoreService: SubUserStoreService,
    private _notificService: NotificService,
    private dialog: MatDialog,
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId))

    translate.addLangs(this.localizeHelperService.localizationAllowed)
    translate.setDefaultLang('en')

    if (this.subUserStoreService.currentLanguage) { return }
    const browserLang = translate.getBrowserLang()
    let lsLang = localStorage.getItem('currentLanguage')

    if (lsLang !== null) {
      this.localizeHelperService.setLang(lsLang)
    } else if (browserLang.match(/en|ru|ua|fr|de|ar|pt|hi|pl|es|tr|zh|ko|it|jp/)) {
      this.localizeHelperService.setLang(browserLang)
    } else {
      this.localizeHelperService.setLang('en')
    }
  }

  ngOnInit() {
    this._notificService.GetNotification({ lang: this.subUserStoreService.currentLanguage, course: 0 }).subscribe((notific: any) => {
      if(notific && notific.emergency) {
        let content
        if (notific.notific_contents.length > 1) {
          let item = notific.notific_contents.findIndex((x) => x.lang === this.subUserStoreService.currentLanguage)
          if (item < 0) {
            item = notific.notific_contents.findIndex((x) => x.lang === 'en')
          }
          content = notific.notific_contents[item]
        } else {
          content = notific.notific_contents[0]
        }

        this.openEmergencyPopup({ ...content, emergency: true })
      }
    })
  }

  public openEmergencyPopup(data) {
    this.dialog
      .open(NotificationsComponent, {
        hasBackdrop: true,
        disableClose: true,
        width: '32rem',
        data: data,
      })
  }
}
