import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { ICourse } from '../../../models/Course.model'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { getLanguageMap } from '../../../helpers/utils/languageMap.util'

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @Input() course: ICourse
  @Input() media: any
  @Output() ended = new EventEmitter<boolean>()
  @Output() started = new EventEmitter<any>()

  public src: any
  public subtitlesForm: UntypedFormGroup
  public currentLanguage: string
  public languageMap = getLanguageMap()

  constructor(
    private localizeHelperService: LocalizeHelperService,
  ) { }

  ngOnInit() {
    this.currentLanguage = this.localizeHelperService.getLangForced()

    const courseLang = this.media.subtitles.find((s) => !!s.isDefault)?.language ?? '0'
    const secondLang = this.media.subtitles.length > 0? this.media.subtitles.find((s) => s.language === this.currentLanguage)?.language?? '0' : '0';

    this.subtitlesForm = new UntypedFormGroup({
      subtitle1: new UntypedFormControl(courseLang),
      subtitle2: new UntypedFormControl(secondLang),
    })

    this.subtitlesForm.valueChanges.subscribe(x => {
      let st1 = this.media.subtitles.find((s) => s.language === x.subtitle1)?.url?? null;
      let st2 = this.media.subtitles.find((s) => s.language === x.subtitle2)?.url?? null;

      this.initSubtitles(st1, st2);
    })

    let st1 = this.media.subtitles.find((s) => s.language === this.subtitlesForm.value.subtitle1)?.url?? null;
    let st2 = this.media.subtitles.find((s) => s.language === this.subtitlesForm.value.subtitle2)?.url?? null;

    this.initSubtitles(st1, st2);
  }

  public initSubtitles(main: string, second: string) {
    this.fetchAndParseVTT(main).then((subtitlesMain) => {
      this.fetchAndParseVTT(second).then((subtitlesSecond) => {
        this.displaySubtitles(document.getElementById('video'), subtitlesMain, subtitlesSecond);
      });
    });
  }

  public videoStarted(element) {
    this.started.emit(element)
  }

  public videoLoaded(element) {
    if ('disablePictureInPicture' in element) {
      element.disablePictureInPicture = true
    }
  }

  public exitFullScreen() {
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    }

    this.ended.emit(true)
  }

  public noDownload(e) {
    e.preventDefault()
    return false
  }

  public displaySubtitles(videoElement: any, subtitlesMain: Array<any>, subtitlesSecond: Array<any>) {
    const mainSubtitleDiv = document.getElementById('main-subtitle');
    const secondSubtitleDiv = document.getElementById('second-subtitle');

    videoElement.ontimeupdate = () => {
      const currentTime = videoElement.currentTime;
      if(subtitlesMain) {
        const mainSubtitle = subtitlesMain.find(sub => currentTime >= sub.start && currentTime <= sub.end);
        mainSubtitleDiv.textContent = mainSubtitle ? mainSubtitle.text : '';
      } else {
        mainSubtitleDiv.textContent = '';
      }

      if(subtitlesSecond) {
        const secondSubtitle = subtitlesSecond.find(sub => currentTime >= sub.start && currentTime <= sub.end);
        secondSubtitleDiv.textContent = secondSubtitle ? secondSubtitle.text : '';
      } else {
        secondSubtitleDiv.textContent = '';
      }
    };
  }

  async fetchAndParseVTT(url: string) {
    if(!url) {
      return null;
    }

    const response = await fetch(url);
    const text = await response.text();
    const parsedSubtitles = [];

    const lines = text.split('\n');
    let currentSubtitle = { start: 0, end: 0, text: '' };

    lines.forEach((line, index) => {
      if (line.includes('-->')) {
        const [start, end] = line.split(' --> ');
        currentSubtitle.start = this.parseVTTTime(start);
        currentSubtitle.end = this.parseVTTTime(end);
      } else if (line.trim() === '' && currentSubtitle.text) {
        parsedSubtitles.push({ ...currentSubtitle });
        currentSubtitle = { start: 0, end: 0, text: '' };
      } else {
        currentSubtitle.text += line.trim() + ' ';
      }
    });

    return parsedSubtitles;
  }

  parseVTTTime(timeString) {
    const parts = timeString.split(':');

    if (parts.length === 3) {
      // Format: HH:MM:SS.SSS
      const [hours, minutes, seconds] = parts;
      return parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds);
    } else {
      // Format: MM:SS.SSS
      const [minutes, seconds] = parts;
      return parseFloat(minutes) * 60 + parseFloat(seconds);
    }
  }
}
