import 'hammerjs'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from "@sentry/angular"

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import 'zone.js'

import { defineCustomElements } from '@teamhive/lottie-player/loader'

if (environment.name != 'development') {
  Sentry.init({
    dsn: "https://d7878ed9ab9bbf0f0524dc1a10ba5fe8@o4507866473824256.ingest.us.sentry.io/4507866634649600",
    integrations: [],
    environment: environment.name
  });
}

if (environment.production) {
  enableProdMode()
}

defineCustomElements(window)

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
}

if (document.readyState === 'complete') {
  bootstrap()
} else {
  document.addEventListener('DOMContentLoaded', bootstrap)
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
  .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}
