<div class="lessonContent" *ngIf="mapData$ | async; else elseBlock" id="lessonsMap" #lessonsMap
  (mousedown)="horizontalScroll()" (mousewheel)="horizontalScrollWheel()">
  <div dir="ltr" class="unit">
    <div appSounds *ngIf="(mapData$ | async)?.unitsPaginate?.prevUnitId as prevUnitId" class="nextUnit prev active"
      (click)="prevUnit(prevUnitId)">
      <img src="/assets/images/lessons/items/orange-prev.png" alt="previous page" />
    </div>
    <div class="island">
      <div class="col">
        <img class="palm" src="/assets/images/anim/palm_anim.png" alt=" " />
      </div>

      <!-- starts cycle -->
      <ng-container
        *ngFor="let lesson of (mapData$ | async)?.courseWithUnit?.units[0]?.lessons; let lessonIndex = index">
        <div class="col" [ngClass]="{
            'type-a': lessonIndex % 4 == 0,
            'type-b': lessonIndex % 4 == 1,
            'type-c': lessonIndex % 4 == 2,
            'type-d': lessonIndex % 4 == 3
          }" *ngIf="
            lesson.type.name !== 'Unit Test' &&
            lesson.type.name !== 'Print' &&
            (!ageRange || (ageRange === 1 && (lesson.type.slug === 'video' || lesson.type.slug === 'video-song' || lesson.type.slug === 'book')) || (ageRange === 2 && lesson.type.slug !== 'video' && lesson.type.slug !== 'video-song'))
          ">
          <img class="palm" *ngIf="lessonIndex === 1" src="/assets/images/anim/palm_anim.png" alt=" " />
          <img class="palm" *ngIf="lessonIndex === 2" src="/assets/images/anim/palm_anim.png" alt=" " />
          <ng-container *ngIf="!lesson.completed && !lesson.current && lesson.type.name !== 'Unit Test'">
            <div class="lesson" appSounds (click)="goToLesson(lesson)" [ngClass]="{
                completed: lesson.completed,
                locked: isLessonLocked(lesson),
                minitest: lesson.type.name === 'Mini Test'
              }">
              <div *ngIf="lesson.type.name !== 'Mini Test'" class="bonus star" [ngClass]="{ active: lesson.completed }">
                <span>{{ lesson.completed?.repeat }}</span>
              </div>
              <div *ngIf="lesson.type.name === 'Mini Test'" class="bonus cup" [ngClass]="{
                  bronze: lesson.completed?.progress >= 80 && lesson.completed?.progress < 90,
                  silver: lesson.completed?.progress >= 90 && lesson.completed?.progress < 100,
                  gold: lesson.completed?.progress == 100,
                  locked: isLessonLocked(lesson)
                }"></div>
              <span class="title">{{ lessonIndex + 1 }}</span>
              <div *ngIf="isLessonLocked(lesson)" class="type games" [ngClass]="{
                  test: lesson.type.name === 'Mini Test',
                  games: lesson.type.name === 'Game',
                  videosong: lesson.type.name === 'Video Song',
                  video: lesson.type.name === 'Video',
                  book: lesson.type.name === 'Book'
                }"></div>
              <ng-lottie *ngIf="!isLessonLocked(lesson)" class="type lottie-egg"
                (animationCreated)="animationCreated($event, lessonIndex)" (mouseenter)="playAnim(lessonIndex)"
                (mouseleave)="stopAnim(lessonIndex)"
                [options]="{ path: getEggPath(lesson.type.name), autoplay: false }">
              </ng-lottie>

              <div *ngIf="lesson.current" class="dino"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="(lesson.completed || lesson.current) && lesson.type.name !== 'Unit Test'">
            <div appSounds class="lesson" (click)="goToLesson(lesson)" [ngClass]="{
                completed: lesson.completed,
                minitest: lesson.type.name === 'Mini Test',
                bronze: lesson.completed?.progress >= 70 && lesson.completed?.progress < 80,
                silver: lesson.completed?.progress >= 80 && lesson.completed?.progress < 90,
                gold: lesson.completed?.progress >= 90
              }">
              <div *ngIf="lesson.type.name !== 'Mini Test'" class="bonus star" [ngClass]="{ active: lesson.completed }">
                <span>{{ lesson.completed?.repeat }}</span>
              </div>
              <div *ngIf="lesson.type.name === 'Mini Test'" class="bonus cup" [ngClass]="{
                  bronze: lesson.completed?.progress >= 80 && lesson.completed?.progress < 90,
                  silver: lesson.completed?.progress >= 90 && lesson.completed?.progress < 100,
                  gold: lesson.completed?.progress == 100
                }"></div>
              <span class="title">{{ lessonIndex + 1 }}</span>
              <ng-lottie class="type lottie-egg" (animationCreated)="animationCreated($event, lessonIndex)"
                (mouseenter)="playAnim(lessonIndex)" (mouseleave)="stopAnim(lessonIndex)"
                [options]="{ path: getEggPath(lesson.type.name), autoplay: false }">
              </ng-lottie>

              <div *ngIf="lesson.current" #target class="dino" id="myDino"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- end cycle -->
      <div class="col">
        <img class="palm" src="/assets/images/anim/palm_anim.png" alt=" " />
      </div>
    </div>
    <div *ngIf="(mapData$ | async)?.unitsPaginate?.nextUnitId as nextUnitId" class="nextUnit active" appSounds
      (click)="nextUnit(nextUnitId)">
      <img src="/assets/images/lessons/items/orange-next.png" alt="next page" />
    </div>
    <ng-container *ngFor="let lesson of (mapData$ | async)?.courseWithUnit?.units[0]?.lessons; let lessonIndex = index">
      <div class="boat-part" *ngIf="lesson.type.name == 'Unit Test'">
        <div class="boat" *ngIf="lesson.type.name == 'Unit Test'" [ngClass]="{
            completed: lesson.completed,
            locked: isLessonLocked(lesson)
          }" appSounds (click)="goToLesson(lesson)">
          <div class="bonus" [ngClass]="{
              bronze: lesson.completed?.progress >= 70 && lesson.completed?.progress < 80,
              silver: lesson.completed?.progress >= 80 && lesson.completed?.progress < 90,
              gold: lesson.completed?.progress >= 90
            }"></div>
          <span>{{ lessonIndex + 1 }}</span>
          <div *ngIf="lesson.current" #target class="dino" id="myDino"></div>
        </div>
      </div>
    </ng-container>
  </div>

  <section class="lesson-progress-bar">
    <span class="step" *ngFor="let x of [].constructor((mapData$ | async)?.unitsPaginate?.totalUnits); let i = index"
      [ngClass]="{ 'current-step': i == (mapData$ | async)?.unitsPaginate?.currentUnitIndex }"></span>
    <ng-container *ngIf="(mapData$ | async)?.unitsPaginate as unitsPaginate">
      <span class="progress">{{ unitsPaginate.currentUnitIndex + 1 }} of {{ unitsPaginate.totalUnits
        }}</span>
    </ng-container>
  </section>
</div>

<ng-template #elseBlock>
  <div class="loader-back">
    <div class="loaderBlock">
      <div class="{{ loaderClass }}"></div>
    </div>
  </div>
</ng-template>