<section class="curriculum" [ngClass]="{ dashboard: !isDialog }">
  <app-profile-sidebar *ngIf="isDialog !== true"></app-profile-sidebar>

  <section class="content" [ngClass]="{ 'blue-background': isDialog }">
    <button mat-icon-button [routerLink]="closeRoute" color="warn" *ngIf="isDialog !== true">
      <img src="/assets/images/icons/closeIcon.svg" alt="close" />
    </button>

    <section *ngIf="type === 'school' && !classrooms.length" class="report" [ngClass]="{ 'blue-background': isDialog }">
      <div class="title">
        {{ 'LINKS.LESSON_PLAN' | translate }}
        <div class="margin-b">{{ 'TEXT.CURICULUM' | translate }}</div>
      </div>
      <span>{{ 'CURRICULUM.NO_CLASSROOM' | translate }}</span>
    </section>

    <section *ngIf="data; else elseBlock" class="report" [ngClass]="{ 'blue-background': isDialog }">
      <div class="select-course block" *ngIf="!isDialog && !isTeacherArea"></div>
      <span appSounds *ngIf="isDialog === true" class="close" (click)="closeCurriculum()"><img
          src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></span>
      <div class="title" *ngIf="isDialog !== true">
        {{ 'LINKS.LESSON_PLAN' | translate }}
        <div [style.color]="'#3b4f5b'" class="margin-b">{{ 'TEXT.CURICULUM' | translate }}</div>

        <h3>
          <span class="courseName-totalActivities"><span>{{ courseName }} </span>- {{ 'LESSON_PLAN.TOTAL' | translate
            }}: {{ totalActivities }}</span>
        </h3>
      </div>
      <div class="title" *ngIf="isDialog === true" [style.color]="'white'">
        {{ 'LINKS.LESSON_PLAN' | translate }}
        <div [style.color]="'white'" class="margin-b">{{ 'TEXT.CURICULUM' | translate }}</div>

        <h3 [style.color]="'white'" class="courseName-totalActivities">
          <span [style.color]="'white'">{{ courseName }} </span>- {{ 'LESSON_PLAN.TOTAL' | translate }}: {{
          totalActivities }}
        </h3>
      </div>
      <article class="contentWrapper">
        <div class="lessonsFilters">
          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <mat-select [(value)]="selectType" disableOptionCentering>
                <mat-option (click)="getLessonsByType()" value="all">
                  {{ 'LESSON_DROPDOWN.ALL' | translate }}
                </mat-option>
                <mat-option (click)="getLessonsByType('video')" value="video">
                  {{ 'LESSON_DROPDOWN.LESSONS' | translate }}
                </mat-option>
                <mat-option (click)="getLessonsByType('game')" value="game">
                  {{ 'LESSON_DROPDOWN.GAME' | translate }}
                </mat-option>
                <mat-option (click)="getLessonsByType('book')" value="book">
                  {{ 'LESSON_DROPDOWN.BOOKS' | translate }}
                </mat-option>
                <mat-option (click)="getLessonsByType('video-song')" value="video-song">
                  {{ 'LESSON_DROPDOWN.SONGS' | translate }}
                </mat-option>
                <mat-option (click)="getLessonsByType('print')" value="print">
                  {{ 'LESSON_DROPDOWN.PRINT' | translate }}
                </mat-option>
                <mat-option (click)="getLessonsByType('minitest')" value="minitest">
                  {{ 'LESSON_DROPDOWN.TESTS' | translate }}
                </mat-option>
              </mat-select>
            </div>
          </div>

          <div class="form-fields-container full-width" *ngIf="!isDialog && !isTeacherArea">
            <div class="form-field-container">
              <mat-select [(value)]="courseCurrent" (selectionChange)="showLessonPlan()"
                placeholder="{{ 'PLACEHOLDERS.LANG_COURSE' | translate }}">
                <mat-option *ngFor="let course of subuserCurrent?.courses" [value]="course">{{ formatLangName(course)
                  }}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-fields-container full-width" *ngIf="type === 'school'">
            <div class="form-field-container">
              <mat-select [(value)]="selectedClassroom" disableOptionCentering>
                <mat-option *ngFor="let classroom of classrooms" (click)="selectClassroom(classroom)"
                  [value]="classroom"> {{ classroom.classroomName }}</mat-option>
              </mat-select>
            </div>
          </div>

          <div class="filter">
            <form (ngSubmit)="handleSearch(unitName.value)">
              <div class="search-btn">
                <div class="form-fields-container full-width">
                  <div class="form-field-container position">
                    <input type="text" placeholder="Search" #unitName />
                    <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="unitName.value = ''; handleSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
                <button type="submit" class="search" mat-flat-button color="primary">
                  {{ 'BUTTONS.SEARCH' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ng-container *ngIf="selectType !== 'prints'">
          <ng-container *ngIf="courseUnits?.length !== 0; else elseBlock">
            <div class="head">
              <span>{{ 'LESSON_PLAN.HEAD_NAME' | translate }} </span>

              <!-- parent headers-->
              <span *ngIf="type === 'parent'">{{ 'LESSON_PLAN.HEAD_TIME' | translate }} </span>
              <span *ngIf="type === 'parent'">{{ 'LESSON_PLAN.HEAD_DATE' | translate }} </span>

              <!-- school headers-->
              <span *ngIf="type === 'school'">{{ 'LESSON_PLAN.HEAD_ASSIGNMENT_DATE' | translate }}</span>
              <span>Content </span>
            </div>
            <div #lessonsCont class="lessonsContainer">
              <div class="paginationWrapper">
                <mat-select (selectionChange)="handlePageSizeChange($event.value)" [(value)]="pagination.pageSize">
                  <mat-select-trigger>1 - {{ pagination.pageSize }} of {{ pagination.totalItems }}</mat-select-trigger>
                  <ng-container *ngFor="let size of pageSizes">
                    <mat-option [value]="size">{{ size }}</mat-option>
                  </ng-container>
                </mat-select>
                <pagination-controls responsive="true" (pageChange)="handlePageChange($event)">
                </pagination-controls>
              </div>
              <div class="unitContent"
                *ngFor="let unit of courseUnits | paginate: { itemsPerPage: pagination.pageSize, currentPage: pagination.currentPage, totalItems: pagination.totalItems }"
                [ngClass]="{ current: unit.current }">
                <mat-accordion *ngIf="unit.lessons.length > 0" [ngClass]="{ 'blue-background': isDialog }">
                  <mat-expansion-panel hideToggle [expanded]="isTeacherArea || unit.current ? true : false">
                    <mat-expansion-panel-header>
                      <mat-panel-title [ngClass]="{ current: unit.current }">
                        <h1>{{ formatMultiName(unit.name) }}</h1>
                        <div class="arrow"></div>
                      </mat-panel-title>
                      <mat-panel-description> </mat-panel-description>
                      <div class="right-block">
                        <ng-container *ngFor="let lesson of unit.lessons; let index = index">
                          <div *ngIf="lesson.typeId == 3" class="right-div"
                            (click)="openTestPlanPopup(lesson); $event.stopPropagation()">
                            <span>{{ 'LEARNING_REPORT.SEE_PLAN' | translate }}</span>
                            <mat-icon color="primary">remove_red_eye</mat-icon>
                          </div>
                        </ng-container>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="lessonsContent" *ngFor="let lesson of unit.lessons; let index = index" [ngClass]="{
                        last: lesson.type.name == 'Mini Test' && selectType !== 'minitest',
                        current: lesson.current
                      }">
                      <div class="lessonItem" [ngClass]="{ print: lesson.typeId == 10 }">
                        <div class="heading-img">
                          <p>
                            <span *ngIf="lesson.typeId != 10">{{ index + 1 }}.</span>
                            <ng-container>
                              <ng-container *ngIf="lesson.isFree || userHasAccess; else elseLink">
                                <a *ngIf="lesson.typeId != 10"
                                  (click)="savePreference(lesson)" class="lesson-link">{{formatMultiName(lesson.name) }}</a>
                                <a *ngIf="lesson.typeId == 10" [ngClass]="{ inactive: printLinkInactive[index] }"
                                  (click)="downloadPdf(lesson, index)" class="lesson-link">{{
                                  formatMultiName(lesson.name) }}</a>
                                <span *ngIf="printLinkInactive[index]"> Loading...</span>
                              </ng-container>
                              <ng-template #elseLink>
                                <a class="lesson-link" (click)="goToLesson(lesson)">{{ formatMultiName(lesson.name)
                                  }}</a>
                              </ng-template>
                            </ng-container>
                          </p>
                          <div class="myDino" *ngIf="lesson.current">
                            <img src="assets/images/progress_dino.png" alt="dino marker" />
                          </div>
                        </div>

                        <div class="right-block">
                          <ng-container *ngIf="type === 'parent'; else elseType">
                            <span *ngIf="lesson.type.name != 'Mini Test' && lesson?.completed">
                              <img style="width: 2rem" src="assets/images/stars/gold.svg" alt="star" />
                              <span>{{ lesson.completed.repeat }}</span>
                            </span>

                            <p class="success"
                              *ngIf="lesson.completed?.progress >= 90 && lesson.completed?.progress != null && lesson.type.name == 'Mini Test'">
                              {{ lesson.completed?.progress }}%</p>
                            <p class="nominally"
                              *ngIf="lesson.completed?.progress < 90 && lesson.completed?.progress >= 70 && lesson.completed?.progress != null && lesson.type.name == 'Mini Test'">
                              {{ lesson.completed?.progress }}%</p>
                            <p class="fail"
                              *ngIf="lesson.completed?.progress != null && lesson.completed?.progress < 70 && lesson.type.name == 'Mini Test'">
                              {{ lesson.completed?.progress }}%</p>
                            <p class="date" *ngIf="lesson.completed?.updatedAt">
                              {{ lesson.completed.updatedAt | date : 'MM.dd.yyyy HH:mm:ss' }}
                            </p>
                          </ng-container>
                          <ng-template #elseType>
                            <ng-container *ngIf="lesson.assignment; else elseAssignment">
                              <span class="assignment-dueDate" (click)="openEditAssignmentDialog(lesson.assignment)">{{
                                lesson.assignment.dueDate | date : 'MMM dd, yyyy' }}</span>
                            </ng-container>
                            <ng-template #elseAssignment>
                              <span class="add-assignment" (click)="openAddAssignmentDialog(lesson)"><img
                                  src="assets/images/icons/green-plus.svg" alt="add assignment" /></span>
                            </ng-template>
                          </ng-template>
                        </div>
                      </div>

                      <div class="print-cont" *ngIf="lesson.prints">
                        <div class="printMat" *ngFor="let print of lesson.prints; let index = index">
                          <div class="lessonItem">
                            <p>
                              <span>P{{ index + 1 }}.</span>
                              <a (click)="completePrint(print.id, print.completed)" target="_blank"
                                class="lesson-link print-link" [href]="print.src">{{ print.name }}</a>
                            </p>
                            <div class="right-block">
                              <div class="printStar" *ngIf="print.completed.length > 0">
                                <img style="width: 2rem" src="assets/images/stars/gold.svg" alt="star" />
                                <span class="count">{{ print.completed[0]?.repeat }}</span>

                                <span>{{ print.completed[0]?.updatedAt | date : 'MM.dd.yyyy HH:mm:ss' }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div class="paginationWrapper">
                <mat-select (selectionChange)="handlePageSizeChange($event.value)" [(value)]="pagination.pageSize">
                  <mat-select-trigger>1 - {{ pagination.pageSize }} of {{ pagination.totalItems }}</mat-select-trigger>
                  <ng-container *ngFor="let size of pageSizes">
                    <mat-option [value]="size">{{ size }}</mat-option>
                  </ng-container>
                </mat-select>
                <pagination-controls responsive="true" (pageChange)="handlePageChange($event)">
                </pagination-controls>
              </div>
              <div *ngIf="!pdfLoading && pdfKeysAvailableLang.includes(courseName)" class="link active margin-left"
                (click)="getPDFKeywords()">{{ courseName }} Worksheets - Answer Keys</div>
              <div *ngIf="pdfLoading" class="link">Loading ...</div>
            </div>
          </ng-container>

          <div class="invisible notFoundBlock" #notFoundBlock>
            <h3>{{ 'LESSON_PLAN.NOT_FOUND' | translate }}</h3>
          </div>

          <ng-template #elseBlock>
            <div>
              <h3>{{ 'LESSON_PLAN.NOT_FOUND' | translate }} {{ selectType }}</h3>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="selectType === 'prints'">
          <ng-container *ngIf="prints?.printsWithoutUnit.length !== 0; else elsePrintBlock">
            <h3>{{ 'LESSON_PLAN.TOTAL' | translate }} {{ totalActivities }}</h3>
            <div class="head">
              <span>{{ 'LESSON_PLAN.HEAD_NAME' | translate }} </span>

              <!-- parent headers-->
              <span *ngIf="type === 'parent'">{{ 'LESSON_PLAN.HEAD_TIME' | translate }} </span>
              <span *ngIf="type === 'parent'">{{ 'LESSON_PLAN.HEAD_DATE' | translate }} </span>

              <!-- school headers-->
              <span *ngIf="type === 'school'">{{ 'LESSON_PLAN.HEAD_ASSIGNMENT_DATE' | translate }}</span>
            </div>
            <ng-container *ngIf="prints?.prints">
              <div class="unitContent prints" *ngFor="let unit of prints?.detailedReportPrints">
                <ng-container *ngIf="unit?.lessons.length !== 0">
                  <mat-accordion>
                    <mat-expansion-panel hideToggle [expanded]="true" [ngClass]="{ 'blue-background': isDialog }">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <h1>{{ unit.name }}</h1>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-container *ngIf="unit?.lessons.length !== 0" else elseBlock>
                        <div class="lessonsContent" *ngFor="let lessons of unit?.lessons">
                          <div class="print-cont">
                            <div class="printMat" *ngFor="let print of lessons?.prints; let index = index">
                              <div class="lessonItem">
                                <p>
                                  <span>P{{ index + 1 }}.</span>
                                  <a (click)="completePrint(print.id, print.completed)" target="_blank"
                                    class="lesson-link print-link" [href]="print.src">{{ print.name }}</a>
                                </p>
                                <div class="right-block">
                                  <div class="printStar">
                                    <img *ngIf="print.completed.length > 0" style="width: 2rem"
                                      src="assets/images/stars/gold.svg" alt="star" /><span class="count">{{
                                      print.completed[0]?.repeat }}</span>
                                    <span>{{ print.completed[0]?.updatedAt | date : 'MM.dd.yyyy HH:mm:ss' }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="!prints?.prints">
              <div class="searchPrint" *ngFor="let printContent of prints?.printsWithoutUnit; let index = index">
                <div class="print">
                  <p>
                    <span>P{{ index + 1 }}.</span>
                    <a (click)="completePrint(printContent.id, printContent.completed)" target="_blank"
                      class="lesson-link print-link" [href]="printContent.src">{{ printContent.name }}</a>
                  </p>
                  <span> <img *ngIf="printContent.completed.length > 0" style="width: 2rem"
                      src="assets/images/stars/gold.svg" alt="star" />{{ printContent.completed[0]?.repeat }}</span>
                  <a target="_blank" class="materialPrint" [href]="printContent.src" color="primary" mat-button>
                    <span></span>
                  </a>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #elsePrintBlock>
            <div>
              <h3>{{ 'LESSON_PLAN.NOT_FOUND' | translate }} {{ selectType }}</h3>
            </div>
          </ng-template>
        </ng-container>
      </article>
    </section>
    <ng-template #elseBlock>
      <div class="loader-back" [ngClass]="{ 'blue-background': isDialog }">
        <div class="loaderBlock">
          <ng-lottie style="width: 250px" class="lottie_p"
            [options]="{ path: '/assets/images/anim/DINOLINGO-TERI-TIGHTROPE.json' }"> </ng-lottie>

          <p [ngClass]="{ 'blue-background': isDialog }">PLEASE STAND BY</p>
          <p [ngClass]="{ 'blue-background': isDialog }">New Activities Loading</p>
        </div>
      </div>
    </ng-template>
  </section>
</section>